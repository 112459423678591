import { useCallback } from 'react';
import { cn, Switch } from '@divlab/divanui';

import useMedias from '@Hooks/useMedias';
import * as Filtrator from '@Stores/Filtrator';
import useDeps from '@Contexts/DI/useDeps';
import useNavigation from '@Navigation/useNavigation';
import Button from '@UI/Button';
import AllFiltersButton from '../AllFiltersButton';
import styles from './QuickFilters.module.css';

import type { FC, HTMLAttributes, MouseEvent } from 'react';
import type { FiltersGroup, FilterView } from '@Types/Filters';
import type { CheckboxItemData } from '@Components/CheckboxList';

export interface QuickFiltersProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  priorityFilters: readonly FiltersGroup[];
  hiddenPriorityFilters?: boolean;
  view?: FilterView;
  onOpen?: (e: MouseEvent, id: string, filterView: FilterView) => void;
}

const QuickFilters: FC<QuickFiltersProps> = ({
  className,
  priorityFilters,
  hiddenPriorityFilters,
  view = 'default',
  onOpen,
  ...restProps
}) => {
  const { pathname, openPage } = useNavigation();
  const { isDesktop } = useMedias();
  const filtrator = Filtrator.useFiltrator('catalog');
  const isViewSticky = view === 'sticky';
  const { analytics } = useDeps();

  // Ссылка для применения выбранных фильтров
  const getAppliedUrl = useCallback(
    (searchParams: string) => {
      const chunks = pathname.split('/');

      // Генерируем ссылку без номера текущей страницы, так как после фильтрации
      // пользователь должен попадать на первую страницу
      const pathnameWithoutPage = chunks
        .filter((chunk) => {
          const isPage = chunk.match(/page-[0-9]{1,}/);

          return !isPage;
        })
        .join('/');

      return `${pathnameWithoutPage}${searchParams}`;
    },
    [pathname],
  );

  const handleOpen = useCallback(
    (e: MouseEvent, selectedFilterId: string, filterView: FilterView) => {
      if (onOpen) onOpen(e, selectedFilterId, filterView);
    },
    [onOpen],
  );

  const handleToggleSwitch = useCallback(
    async (_e, item: CheckboxItemData) => {
      const opts = { id: item.data.parameterId, value: item.data.value };

      if (item.checked) {
        Filtrator.removeCheckbox('catalog', opts);
      } else {
        Filtrator.addCheckbox('catalog', opts);
      }

      analytics.dispatchEvent('filters.toggleSwitch', {
        isActivate: !item.checked,
        label: item.text,
        status: isViewSticky ? 'закреп' : 'не закреп',
      });

      const searchParams = Filtrator.toUrl('catalog');
      const appliedUrl = getAppliedUrl(searchParams);
      await openPage({ url: appliedUrl });
    },
    [getAppliedUrl, openPage, analytics, isViewSticky],
  );

  return (
    <div
      {...restProps}
      className={cn(styles.buttons, { [styles.stickyFilters]: view === 'sticky' }, className)}
    >
      <AllFiltersButton
        className={styles.allFiltersBtn}
        theme={isViewSticky && !isDesktop ? 'secondary' : 'primary'}
        onClick={(e) => handleOpen(e, 'all', view)}
      />

      {!hiddenPriorityFilters &&
        priorityFilters.map((filter) => {
          return filter.theme === 'switch' ? (
            filter.items.map((item, indexItem) => {
              const parameter = filtrator.parameters[item.parameterId];
              const data = filtrator.parameterValues.find(
                (val) => val.parameterId === item.parameterId,
              );
              const val = {
                id: indexItem,
                text: parameter.name,
                checked: !!data.value,
                data,
              };

              return (
                item.theme === 'checkbox' && (
                  <Button
                    className={cn(styles.button, styles.switcher)}
                    view='rounded'
                    theme='transparent'
                    key={item.parameterId}
                    onClick={(e) => handleToggleSwitch(e, val)}
                  >
                    <div className={styles.switchGroup}>
                      <Switch
                        className={cn(styles.switch, { [styles.actived]: !!data.value })}
                        name={item.parameterId}
                        checked={!!data.value}
                      />
                      {parameter.name}
                    </div>
                  </Button>
                )
              );
            })
          ) : (
            <Button
              className={cn(styles.button, styles.secondary)}
              view='rounded'
              theme='transparent'
              key={filter.name}
              count={filter.count}
              onClick={(e) => handleOpen(e, filter.name, view)}
            >
              {filter.name}
            </Button>
          );
        })}
    </div>
  );
};

export default QuickFilters;
