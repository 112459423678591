import { memo, useState, useCallback } from 'react';
import { cn } from '@divlab/divanui';

import Link from '@Navigation/Link';
import useMedias from '@Hooks/useMedias';
import useDeps from '@Contexts/DI/useDeps';
import styles from './Item.module.css';

import type { FC, MouseEvent, AnchorHTMLAttributes } from 'react';
import type { SubcategoryData } from '@Types/Category';

export interface ItemProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  className?: string;
  subcategory: SubcategoryData;
}

const Item: FC<ItemProps> = (props) => {
  const { className, subcategory, onMouseEnter, onMouseLeave, ...restProps } = props;
  const { isMobileM } = useMedias();
  const [hovered, setHovered] = useState(false);
  const src = `${subcategory.icon}${hovered || subcategory.active ? '#hover' : ''}`;
  const { analytics } = useDeps();

  const handleMouseEnter = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      setHovered(true);
      if (onMouseEnter) onMouseEnter(e);
    },
    [onMouseEnter],
  );

  const handleMouseLeave = useCallback(
    (e: MouseEvent<HTMLAnchorElement>) => {
      setHovered(false);
      if (onMouseLeave) onMouseLeave(e);
    },
    [onMouseLeave],
  );

  const handleClickLink = useCallback(() => {
    if (window.cancelClick) return;

    analytics.dispatchEvent('subcategories.item.click', {
      subcategory,
    });
  }, [subcategory, analytics]);

  return (
    <Link
      {...restProps}
      className={cn(styles.item, { [styles.active]: subcategory.active }, className)}
      to={subcategory.link}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClickLink}
    >
      {!isMobileM && (
        <div className={styles.wrapperIcon}>
          <img alt={subcategory.alt} title={subcategory.alt} className={styles.icon} src={src} />
        </div>
      )}

      <div className={styles.title}>
        {subcategory.title}
        {subcategory.count > 0 && (
          <>
            <sup className={styles.count}>{`${subcategory.count}`}</sup>
          </>
        )}
      </div>
    </Link>
  );
};

export default memo(Item);
